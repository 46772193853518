import React from 'react';
import { Router as DefaultRouter, Route, Switch } from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/_renderRoutes';
import RendererWrapper0 from '/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/pages/.umi/LocaleWrapper.jsx'
import _dvaDynamic from 'dva/dynamic'

let Router = require('dva/router').routerRedux.ConnectedRouter;

let routes = [
  {
    "path": "/user",
    "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "layouts__UserLayout" */'../../layouts/UserLayout'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
    "routes": [
      {
        "path": "/user",
        "redirect": "/user/login",
        "exact": true
      },
      {
        "path": "/user/login",
        "name": "login",
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__User__models__register.js' */'/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/pages/User/models/register.js').then(m => { return { namespace: 'register',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__User__Login" */'../User/Login'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/user/register",
        "name": "register",
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__User__models__register.js' */'/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/pages/User/models/register.js').then(m => { return { namespace: 'register',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__User__Register" */'../User/Register'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/user/register-result",
        "name": "register.result",
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__User__models__register.js' */'/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/pages/User/models/register.js').then(m => { return { namespace: 'register',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__User__RegisterResult" */'../User/RegisterResult'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "component": () => React.createElement(require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/node_modules/umi-build-dev/lib/plugins/404/NotFound.js').default, { pagesPath: 'src/pages', hasRoutesInConfig: true })
      }
    ]
  },
  {
    "path": "/",
    "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "layouts__BasicLayout" */'../../layouts/BasicLayout'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
    "Routes": [require('../Authorized').default],
    "authority": [
      "user"
    ],
    "routes": [
      {
        "path": "/dashboard",
        "name": "dashboard",
        "component": _dvaDynamic({
  app: window.g_app,
models: () => [
  import(/* webpackChunkName: 'p__Dashboard__models__activities.js' */'/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/pages/Dashboard/models/activities.js').then(m => { return { namespace: 'activities',...m.default}}),
  import(/* webpackChunkName: 'p__Dashboard__models__chart.js' */'/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/pages/Dashboard/models/chart.js').then(m => { return { namespace: 'chart',...m.default}}),
  import(/* webpackChunkName: 'p__Dashboard__models__monitor.js' */'/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/pages/Dashboard/models/monitor.js').then(m => { return { namespace: 'monitor',...m.default}})
],
  component: () => import(/* webpackChunkName: "p__Dashboard__Dashboard" */'../Dashboard/Dashboard'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/receive",
        "exact": true
      },
      {
        "path": "/configuration",
        "name": "configuration",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__Configuration__Configuration" */'../Configuration/Configuration'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/receive",
        "name": "receive",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__Receive__Receive" */'../Receive/Receive'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/stockview",
        "name": "stockview",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__Stockview__Stockview" */'../Stockview/Stockview'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/issue",
        "name": "issue",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__Issue__Issue" */'../Issue/Issue'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/task",
        "name": "task",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__Task__Task" */'../Task/Task'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/stocktransfer",
        "name": "stocktransfer",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__StockTransfer__StockTransfer" */'../StockTransfer/StockTransfer'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/productimport",
        "name": "productimport",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__ProductImport__ProductImport" */'../ProductImport/ProductImport'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/manual",
        "name": "manual",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__Manual__Manual" */'../Manual/Manual'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "path": "/customer",
        "name": "customer",
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__Customer__Customer" */'../Customer/Customer'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "component": _dvaDynamic({
  
  component: () => import(/* webpackChunkName: "p__404" */'../404'),
  LoadingComponent: require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/src/components/PageLoading/index').default,
}),
        "exact": true
      },
      {
        "component": () => React.createElement(require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/node_modules/umi-build-dev/lib/plugins/404/NotFound.js').default, { pagesPath: 'src/pages', hasRoutesInConfig: true })
      }
    ]
  },
  {
    "component": () => React.createElement(require('/home/openbravo/CW_Warehouse_preprod/cw_warehouse_client/node_modules/umi-build-dev/lib/plugins/404/NotFound.js').default, { pagesPath: 'src/pages', hasRoutesInConfig: true })
  }
];
window.g_routes = routes;
window.g_plugins.applyForEach('patchRoutes', { initialValue: routes });

// route change handler
function routeChangeHandler(location, action) {
  window.g_plugins.applyForEach('onRouteChange', {
    initialValue: {
      routes,
      location,
      action,
    },
  });
}
window.g_history.listen(routeChangeHandler);
routeChangeHandler(window.g_history.location);

export default function RouterWrapper() {
  return (
<RendererWrapper0>
          <Router history={window.g_history}>
      { renderRoutes(routes, {}) }
    </Router>
        </RendererWrapper0>
  );
}
